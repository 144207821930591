import consumer from "./consumer"

let channel = consumer.subscriptions.create("MapChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        $('#mapImage').attr('src', data.image_src)
        $('#mapLink').attr('href', data.map_url)
    },

    set_image: function (map_id) {
        return this.perform('set_image', {
            map_id: map_id
        });
    }
});

$(document).on('turbolinks:load', function () {
    $('.distribution-country').on('click', function () {
        let id = $(this).attr('data-id')
        if (id === '1') {
            let locale = window.location.pathname.split("/")[1]
            if (!['ukr', 'ru', 'pl', 'eng'].includes(locale)) {
                locale = 'ukr'
            }
            $('#mapImage').attr('src', `/assets/ukraine_${locale}.png`)
            $('#mapLink').attr('href', document.querySelector('#ukraineMapUrl').text)
        } else {
            channel.set_image(id)
        }
    })
})
