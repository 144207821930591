$(document).on('turbolinks:load', function () {
    $('.menuToggle').on('click', function () {
        let menu = $('#menuOverlay')
        if (menu.hasClass('slide-in')) {
            menu.removeClass('slide-in').addClass('slide-out');
        } else if (menu.hasClass('slide-out')) {
            menu.removeClass('slide-out').addClass('slide-in');
        } else {
            menu.addClass('slide-in').removeClass('d-none');
        }
    })

    $('#menuOverlay .nav-link').on('click', function () {
        $('#menuOverlay').removeClass('slide-in').addClass('slide-out')
    })


    // Mark link as active on click
    $('.nav-item').on('click', function () {
        $('.nav-item').removeClass('active')
        $(this).addClass('active')
    })

    $('.distribution-country').on('click', function () {
        $('.distribution-country').removeClass('main-color')
        $(this).addClass('main-color')
    })

    // Prevent turbolinks from refreshing the page if anchor
    document.addEventListener("turbolinks:click", (event) => {
        const anchorElement = event.target
        const isSamePageAnchor = (
            anchorElement.hash &&
            anchorElement.origin === window.location.origin &&
            anchorElement.pathname === window.location.pathname
        )

        if (!isSamePageAnchor) return

        event.preventDefault()

        Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
            event.data.url,
            Turbolinks.uuid()
        )
    })

    $('.brand-container').on('click', function(e){
        let link = $(this).find('.brand-link')
        if (link.css('pointerEvents') === 'none'){
            if (link.hasClass('clicked')){
                link.removeClass('clicked');
            } else {
                e.preventDefault();
                $('.brand-link').removeClass('clicked');
                link.addClass('clicked');
            }
        }
    });
})